












































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}

@Component({
  components: {},
  meta: {
    // sets document title
    title: "Tarjeta Vecino de Ñuñoa",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Conoce las novedades de la Tarjeta Vecino de Ñuñoa."
      },
      keywords: { name: "keywords", content: "Ñuñoa" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class Home extends Vue {
  private win = window;
  private base = window.location;
  private bannerSlide = "1";
  private horizontalPercentageInteres = 0;
  private aBanners: Array<dataBanner> = [];
  private slideIndex: number = 1;
  private verPopUp = true;

  private updated() {
    this.showSlides(this.slideIndex);
  }

  private mounted() {
    this.getBanners();
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private scrollAreaFunctionInteres(info: any) {
    this.horizontalPercentageInteres = info.horizontalPercentage;
  }
  private scroll(direccion: string) {
    const size = (this.$refs.scrollInteres as any).scrollSize;
    const position = (this.$refs.scrollInteres as any).scrollPosition;

    if (direccion == "derecha") {
      if (this.horizontalPercentageInteres >= 1) {
        (this.$refs.scrollInteres as any).setScrollPosition(0, 300);
      } else {
        (this.$refs.scrollInteres as any).setScrollPosition(
          position + 316,
          300
        );
      }
    } else {
      if (position - 316 < 0) {
        if (position > 0) {
          (this.$refs.scrollInteres as any).setScrollPosition(0, 300);
        } else {
          (this.$refs.scrollInteres as any).setScrollPosition(size, 300);
        }
      } else {
        (this.$refs.scrollInteres as any).setScrollPosition(
          position - 316,
          300
        );
      }
    }
  }

  private getBanners() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("slider")
      .then(res => {
        let base = process.env.VUE_APP_BASE_URL;
        this.aBanners = res.data;
        for (let index = 0; index < this.aBanners.length; index++) {
          this.aBanners[index].imagen = base + this.aBanners[index].imagen;
          if (
            this.aBanners[index].imagen_mobile &&
            this.aBanners[index].imagen_mobile != ""
          ) {
            this.aBanners[index].imagen_mobile =
              base + this.aBanners[index].imagen_mobile;
          } else {
            this.aBanners[index].imagen_mobile =
              base + this.aBanners[index].imagen;
          }
          if (!this.aBanners[index].link) {
            this.aBanners[index].link = "#";
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.aBanners = [];
      });
  }
  // Funciones del banner
  // Next/previous controls
  private plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  // Thumbnail image controls
  private currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  private showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    if (slides.length > 0) {
      for (i = 0; i < slides.length; i++) {
        (slides[i] as any).style.display = "none";
      }
      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      (slides[this.slideIndex - 1] as any).style.display = "block";
      dots[this.slideIndex - 1].className += " active";
    }
  }

  private autoscroll() {
    this.plusSlides(1);
    setTimeout(this.autoscroll, 5000);
  }
  // Fin Funciones del banner

  private toLink(link) {
    if (link != "#") {
      window.open(link, "_blank");
    }
  }
}
